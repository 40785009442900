<template>
  <v-container fluid>
    <!-- HubSpot Integration Page -->
    <v-card class="rounded">
      <v-row class="ma-0">
        <v-col>
          <v-card
            color="transparent"
            elevation="0"
            style="padding: 10px; text-align: center !important"
          >
            <v-card-text style="margin-bottom: 25px">
              <div align="center" style="margin-bottom: 10px; margin-top: 20%">
                <h1 style="margin-bottom: 40px">GoDial + HubSpot</h1>
              </div>
              <div style="padding: 5px 15px; text-align: center">
                <div style="text-align: left">
                  <p
                    style="
                            margin-top: 20px;
                            font-size: 18px;
                            font-weight: 600;
                            margin-bottom: 10px;
                          "
                  >
                    How to use?
                  </p>
                  <p>
                    
                    1. Goto Hubspot Settings > Integrations > Private Apps > Create a private app
                    <br>
                    <ul>
                      <li>Give name of the app, logo and description</li>
                      <li>Go to Scope page and give this permissions -> crm.lists (read, write), crm.object.contacts(read, write), crm.object.custom (read,write), crm.object.leads (read, write)</li>
                      <li>Go to webhook page and and set target url which is given below and set Event Throttling to 1 </li>
                      <li>Now click on create app.</li>
                    </ul>
                    2. Enable HubSpot Integration
                    <br />
                    3. Select a list where you want to receive the leads, and
                    click save
                    <br />
                    4. Enter your Access Token for authentication which you will get after the app creation or you can get it by clicking on the app > Auth > Access Token
                    <br />
                    5. Once the token is saved, GoDial will start receiving
                    leads from HubSpot
                    <br />
                  </p>
                </div>
              </div>
            </v-card-text>
            <v-footer color="transparent" absolute>
              <v-flex style="text-align: center !important">
                <p
                  style="
                          font-size: 12px;
                          opacity: 0.9;
                          margin-bottom: 0px;
                        "
                >
                  Feel free to reach out to us at
                  <a href="mailto:support@godial.cc">support@godial.cc</a>
                  if you are interested in these features
                </p>
              </v-flex>
            </v-footer>
          </v-card>
        </v-col>
        <v-col>
          <v-card flat>
            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <v-layout row wrap>
                <v-flex
                  d-flex
                  justify-center
                  class="mt-10"
                  style="padding-top: 22px"
                  >Enable HubSpot
                </v-flex>
                <v-flex class="mt-10" d-flex justify-center>
                  <v-switch
                    v-model="hubSpot.enabled"
                    color="primary"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-card>
          <v-card
            color="transparent"
            elevation="0"
            style="margin-top:25px; padding: 15px 50px; text-align: center"
          >
            <v-card-title primary-title class="justify-center"
              >HubSpot Credentials</v-card-title
            >

            <!-- Access Token -->
            <v-flex xs12 sm12 offset-sm1 md12 offset-md1>
              <v-text-field
                v-model="hubSpot.config.accessToken"
                label="Access Token"
                type="text"
                rows="3"
                :disabled="!hubSpot.enabled"
                prepend-inner-icon="vpn_key"
              ></v-text-field>
            </v-flex>

            <!-- Inquiry List -->
            <v-flex xs12 sm12 offset-sm1 md12 offset-md1>
              <v-autocomplete
                v-model="hubSpot.config.listId"
                :disabled="!hubSpot.enabled"
                class="pt-1"
                :items="lists"
                item-text="name"
                item-value="id"
                label="Select List"
                prepend-inner-icon="view_list"
                autocomplete="false"
                outlined
                dense
              />
            </v-flex>

            <v-card-text>
              <v-flex
                xs12
                sm10
                offset-sm1
                md10
                offset-md1
                style="margin-bottom: 10px"
              >
                <v-btn color="primarygrad" dark block @click="setHubSpot"
                  >Save</v-btn
                >
              </v-flex>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      v-if="hubSpot.enabled && hubSpot.config.listId"
      class="integration-card my-3 pa-2"
    >
      <v-alert
        color="primary"
        icon="mdi-alert"
        theme="dark"
        prominent
        class="mt-2"
      >
        Please copy these details and paste to your hubspot private apps webhook
        page.
      </v-alert>
      <v-card-title primary-title class="integration-title">
        Activate Integration
      </v-card-title>

      <v-card-text class="integration-body">
        <v-row>
          <v-col>
            <v-card flat class="integration-details">
              <!-- Endpoint -->
              <v-row>
                <v-col>
                  <p class="integration-label">Endpoint:</p>
                  <p class="integration-value" ref="curlTextArea">
                    {{ ENDPOINT }}/externals/hubspot?access_token={{ token }}
                  </p>
                </v-col>
              </v-row>

              <v-row class="my-0">
                <v-col>
                  <v-btn color="primary" @click="copyToClipboard">
                    Copy to Clipboard
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../../js/firebase";

export default {
  data() {
    return {
      hubSpot: {
        type: "hubspot",
        config: {
          accessToken: "",
          listId: "",
        },
        companyId: "",
        enabled: false,
      },
      loader: false,
      lists: [],
      token : "",
    };
  },

  computed: {
    ...mapGetters(["ENDPOINT", "companyDetails", "selectedTeam"]),
  },
  watch: {},
  created() {
    this.generateToken();
    this.fetchLists();
    this.getHubSpotDetails();
    this.hubSpot.companyId = this.companyDetails.id;
  },
  methods: {
    generateToken() {
      this.$http
        .get(
          `${this.ENDPOINT}/externals/token?access_token=${this.$store.getters.user.token}`
        )
        .then((response) => {
          this.token = response.body.id;
          console.log("this.token",this.token)
        })
        .catch((err) => {
          this.$swal({ type: "error", text: err.body.error.message });
        });
    },
    copyToClipboard() {
        let curlCommand = this.$refs.curlTextArea.innerText; // Get the text content of the p element
        // Remove http:// or https:// from the URL
        curlCommand = curlCommand.replace(/https?:\/\//, "");

        navigator.clipboard
          .writeText(curlCommand)
          .then(() => {
            this.$swal({ type: "success", text: "Copied to clipboard!" });
          })
          .catch((err) => {
            console.error("Failed to copy!", err);
          });
    },
    async getHubSpotDetails() {
      var resp = await this.$http.get(
        `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=hubspot`
      );

      if (resp.body.length > 0) this.hubSpot = resp.body[0];
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];

        var teamIds = this.getTeamIds();

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;

        if (this.lists.length < 1) {
          this.loader = false;
          return;
        }
      } catch (e) {
        this.loader = false;
        console.log("error in list fetch", e);
      }
    },

    async setHubSpot() {
      this.loader = true;
      try {
        this.hubSpot.companyId = this.companyDetails.id;
        this.hubSpot.type = "hubspot";
        var res = {};
        //If new user
        if (typeof this.hubSpot.id == "undefined") {
          res = await this.$http.post(
            `${this.ENDPOINT}/integrations`,
            this.hubSpot
          );
        } else {
          var data = JSON.parse(JSON.stringify(this.hubSpot));
          delete data.id;
          res = await this.$http.put(
            `${this.ENDPOINT}/integrations/${this.hubSpot.id}`,
            data
          );
        }
        // Success Message
        if (res.status == 200) {
          this.getHubSpotDetails();
          this.loader = false;
          try {
            fb.log("hubSpot_added");
          } catch (e) {
            console.log("Error in FB log", e);
          }
          return this.$swal({
            type: "success",
            text: "HubSpot Added to GoDial Successfully",
          });
        } else {
          return this.$swal({
            type: "warning",
            text: "Something Went Wrong",
          });
        }
      } catch (err) {
        console.log(err);
        this.loader = false;
        return this.$swal({
          type: "warning",
          text: err.body.error.message,
        });
      }
    },
  },
};
</script>

<style></style>
